// const BASE_URL = "http://localhost:5000/resumorph/asia-south1";

const BASE_URL = "https://asia-south1-resumorph.cloudfunctions.net";

export const getUserData = async (userId: string) => {
    const response = await fetch(`${BASE_URL}/getUserData`, {
        method: 'post',
        body: JSON.stringify({
            userId: userId
        })
    });

    const data = await response.json();
    return data;
}

export const signUpUser = async (userId: string, email: string, workExperience: any[], education: any[], skills: string[]) => {
    const response = await fetch(`${BASE_URL}/onboardUser`, {
        method: 'post',
        body: JSON.stringify({
            userId,
            email,
            workExperience: workExperience,
            education,
            skills
        })
    });

    const data = await response.json();
    return data;
}

export const getResumorphResponse = async (
    jobTitle: string,
    jobDescription: string,
    qualifications: string,
    currentRole: string,
    workExperience: string,
    techSkills: string,
    degree: string
) => {
    const response = await fetch(`${BASE_URL}/getResumorphResponse`, {
        method: 'post',
        body: JSON.stringify({
            jobTitle,
            jobDescription,
            qualifications,
            currentRole,
            workExperience,
            techSkills,
            degree
        })
    });

    const data = await response.json();
    return data;
}

export const getColdMessageResponse = async (
    jobTitle: string,
    jobDescription: string,
    qualifications: string,
    currentRole: string,
    workExperience: string,
    techSkills: string,
    degree: string
) => {
    const response = await fetch(`${BASE_URL}/getColdMessageResponse`, {
        method: 'post',
        body: JSON.stringify({
            jobTitle,
            jobDescription,
            qualifications,
            currentRole,
            workExperience,
            techSkills,
            degree
        })
    });

    const data = await response.json();
    return data;
}

export const getCoverLetterResponse = async (
    jobTitle: string,
    jobDescription: string,
    qualifications: string,
    currentRole: string,
    workExperience: string,
    techSkills: string,
    degree: string
) => {
    const response = await fetch(`${BASE_URL}/getCoverLetterResponse`, {
        method: 'post',
        body: JSON.stringify({
            jobTitle,
            jobDescription,
            qualifications,
            currentRole,
            workExperience,
            techSkills,
            degree
        })
    });

    const data = await response.json();
    return data;
}

export const addWorkExperience = async (
    userId: string,
    workExperiences: any[]
) => {
    const response = await fetch(`${BASE_URL}/insertWorkExperience`, {
        method: 'post',
        body: JSON.stringify({
            userId,
            workExperiences
        })
    });

    const data = await response.json();
    return data;
}

export const updateWorkExperience = async (
    userId: string,
    workExperiences: any[]
) => {
    const response = await fetch(`${BASE_URL}/updateWorkExperience`, {
        method: 'post',
        body: JSON.stringify({
            userId,
            workExperiences
        })
    });

    const data = await response.json();
    return data;
}

export const addEducation = async (
    userId: string,
    education: any[]
) => {
    const response = await fetch(`${BASE_URL}/insertEducation`, {
        method: 'post',
        body: JSON.stringify({
            userId,
            education
        })
    });

    const data = await response.json();
    return data;
}

export const updateEducation = async (
    userId: string,
    education: any[]
) => {
    const response = await fetch(`${BASE_URL}/updateEducation`, {
        method: 'post',
        body: JSON.stringify({
            userId,
            education
        })
    });

    const data = await response.json();
    return data;
}

export const addSkills = async (
    userId: string,
    skills: string[]
) => {
    const response = await fetch(`${BASE_URL}/insertSkills`, {
        method: 'post',
        body: JSON.stringify({
            userId,
            skills
        })
    });

    const data = await response.json();
    return data;
}

export const updateSkills = async (
    userId: string,
    skills: string[]
) => {
    const response = await fetch(`${BASE_URL}/updateSkills`, {
        method: 'post',
        body: JSON.stringify({
            userId,
            skills
        })
    });

    const data = await response.json();
    return data;
}

//NEW
export const getResumorphResponsePDF = async (
    jobTitle: string,
    jobDescription: string,
    qualifications: string,
    resume: string
) => {
    const response = await fetch(`${BASE_URL}/getResumeDetails`, {
        method: 'post',
        body: JSON.stringify({
            // jobTitle,
            // jobDescription,
            // qualifications,
            resume
        })
    });

    const data = await response.json();
    return data;
}

export const getResumeDetails = async (
    resume: string
) => {
    const response = await fetch(`${BASE_URL}/getResumeDetails`, {
        method: 'post',
        body: JSON.stringify({
            resume
        })
    });

    const data = await response.json();

    return JSON.parse(data.result.content);
}