import { Button, Grid, Input, LinearProgress, TextField } from "@mui/material";
import { TrialOption, TryItOutMode, TryItOutResultStepLabels, TryItOutStep, TryItOutStepLabels } from "../constants/Constants";
import { useState } from "react";
import StepIndicator from "./common/StepIndicator";
import { BoltSharp, ContactPageSharp, ReviewsSharp } from "@mui/icons-material";
import { getColdMessageResponse, getCoverLetterResponse, getResumeDetails, getResumorphResponse } from "../api/FetchDataService";
import { getPDFText } from "../api/PDFService";
import WorkSelectInput from "./morphChildComponents/WorkSelectInput2";
import { TryItOutIntro } from "./tryItOutChildComponents/TryItOutIntro";
import TryItOutModeSelector from "./tryItOutChildComponents/TryItOutModeSelector";
import { TryItOutAppBar } from "./tryItOutChildComponents/TryItOutAppBar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudArrowUp } from "@fortawesome/free-solid-svg-icons";

const getStyle = (option: any, desiredOption: any) => {
    return option === desiredOption ? "trial-option-button trial-option-button-selected" : "trial-option-button"
}


const TryItOut = () => {

    const [mode, setMode] = useState(TryItOutMode.PDF);
    const [view, setView] = useState(TryItOutStep.INTRO);
    const [loading, setLoading] = useState(false);

    // States related to pdf
    const [resumeFile, setResumeFile] = useState({} as File);
    const [fetchingFile, setFetchingFile] = useState(false);
    const [fileText, setFileText] = useState('');
    const [workExList, setWorkExList] = useState([] as any);
    const [selectedWorkEx, setSelectedWorkEx] = useState(0);
    const [pdfSkills, setPDFSkills] = useState<string[]>([]);

    // Manual Entry
    const [skill, setSkill] = useState('');
    const [skills, setSkills] = useState<string[]>([]);
    const [workExTitle, setWorkExTitle] = useState('');
    const [workExDesc, setWorkExDesc] = useState('');
    const validWorkEx = workExTitle.trim().length > 0 && workExDesc.trim().length > 0 && skills.length > 0;

    // Currently unused
    const [eduLevel, setEduLevel] = useState('');
    const [eduDiscipline, setEduDiscipline] = useState('');

    // Job Details
    const [jobTitle, setJobTitle] = useState('');
    const [jobDesc, setJobDesc] = useState('');
    const [jobReq, setJobReq] = useState('');
    const validJd = jobTitle.trim().length > 0 && jobDesc.trim().length > 0 && jobReq.trim().length > 0;



    const [option, setOption] = useState<TrialOption>();

    const trialButtonOptionStyles = {
        boost: getStyle(option, TrialOption.BOOST),
        cover: getStyle(option, TrialOption.COVER),
        cold: getStyle(option, TrialOption.COLD)
    };

    const [result, setResult] = useState<any>();


    let toolFunction: Function;

    if (option === TrialOption.BOOST) toolFunction = getResumorphResponse;
    if (option === TrialOption.COVER) toolFunction = getCoverLetterResponse;
    if (option === TrialOption.COLD) toolFunction = getColdMessageResponse;


    const addSkill = (event: any) => {
        if(event.code === 'Enter') {
            const newSkill = skill.trim();
            const skillSet = new Set([
                ...skills,
                newSkill
            ]);
            setSkills([...Array.from(skillSet)]);
            setSkill('');
        }
    };

    const workExperienceTitle = mode === TryItOutMode.PDF ? workExList[selectedWorkEx]?.title : workExTitle;
    const workExperienceDescription = mode === TryItOutMode.PDF ? workExList[selectedWorkEx]?.description : workExDesc;
    const workSkills = mode === TryItOutMode.PDF ? pdfSkills : skills;

    const submitFunction = async () => {
        setLoading(true);
        const res = await toolFunction(
            jobTitle,
            jobDesc,
            jobReq,
            workExperienceTitle,
            workExperienceDescription,
            workSkills,
            eduLevel + " " + eduDiscipline
        );
        if (res.error) {
            alert("ERROR");
        } else {
            setLoading(false);
            setView(TryItOutStep.RESULT);

            console.log("Option", option);
            console.log("wahoo: ", res.result.content);

            if (option === TrialOption.BOOST) setResult(JSON.parse(res.result.content).result);
            if (option === TrialOption.COVER) setResult(res.result.content);
            if (option === TrialOption.COLD) setResult(res.result.content);  
        }
    }

    const copyResult2 = () => {
        if (navigator.clipboard) {
            navigator.clipboard.writeText(result).then(() => {
                console.log("copied");
            }).catch((e) => {
                console.log("error: ", e);
            })
        }
    }

    const copyResult1 = () => {
        if (navigator.clipboard) {
            navigator.clipboard.writeText(result.join("\n")).then(() => {
                console.log("copied");
            }).catch((e) => {
                console.log("error: ", e);
            })
        }
    }

    const copyResult = option === TrialOption.BOOST ? copyResult1 : copyResult2;

    const handleFileChange = (event: any) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
          // Handle the selected file here
          setResumeFile(selectedFile);
          console.log('Selected file:', selectedFile);
        }
    };

    const getFileText = () => {
        setFetchingFile(true);
        getPDFText(resumeFile).then((result) => {
            console.log("TryItOut.tsx: ", result);
            setFileText(result?.file);

            if (result && result.file) {
                getResumeDetails(result.file).then((resumeDetails) => {

                    console.log("RESUME DETAILS: ",resumeDetails);
                    let workEx: any[] = [];
                    resumeDetails.workExperience.forEach((value: any, index: any, array: any) => {
                        workEx.push({
                            index: index,
                            ...value
                        })
                    })
                    setWorkExList(workEx);
                    setPDFSkills(resumeDetails.skills);
                }).catch((error) => {
                    console.log(error);
                    throw error;
                }).finally(() => {
                    setFetchingFile(false);
                });
            } else {
                throw new Error("Failed getting resume details");
            }
        }).catch((error) => {
            setFetchingFile(false);
            console.log("TryItOut.tsx: ", error);
            alert("ERROR");
        });
    }

    const handleRadioChange = (e: any) => {
        setSelectedWorkEx(parseInt(e.currentTarget.value));
    }

    if(loading) {
        return (
            <div className="App">
                <TryItOutAppBar />
                <br />
                <div className="w90 left-text">
                    <h1>Try it out !</h1>
                </div>
                <br />
                <StepIndicator
                    activeStep={TryItOutStep.RESULT}
                    steps={TryItOutResultStepLabels}
                />
                <br />
                <div className="w90 loading-message">
                    <LinearProgress color="inherit" />
                    <br />
                    <h3>Processing ...</h3>
                    <div>This could take some time. Please be patient. Do not refresh the page.</div>
                </div>
            </div>
        )
    }


    return (
        <div>
            <div className="App">
                <TryItOutAppBar />
                <br />
                <div className="w90 left-text">
                    <h1>Try it out !</h1>
                </div>
                <br />
                <StepIndicator
                    activeStep={view}
                    steps={TryItOutStepLabels}
                />
                <br />
                {
                    view === TryItOutStep.INTRO ?
                    <>
                        <div className="w90 left-text">
                            <TryItOutIntro />
                            <br />
                            <div className="button-container">
                                <Button size={'small'} variant="contained" className="morph-step-button" onClick={() => setView(TryItOutStep.PDF)}>
                                    Get Started &rarr;
                                </Button> 
                                <br />
                                <div></div>
                            </div>
                        </div>
                    </> :
                    view === TryItOutStep.PDF ?
                    <>
                    <div className="w90">
                        <div className="profile-top-bar">
                            <div className="profile-view-bar">
                                <TryItOutModeSelector selected={mode === TryItOutMode.PDF} onClick={setMode} value={TryItOutMode.PDF} label={"Upload Resume"} />
                                <TryItOutModeSelector selected={mode === TryItOutMode.MANUAL} onClick={setMode} value={TryItOutMode.MANUAL} label={"Enter Work Details"} />
                            </div>
                        </div>
                    </div>
                    <br />
                    {
                        mode === TryItOutMode.PDF ?
                        <>
                            <div className="w90 left-text file-upload-container">
                                <Button
                                style={{
                                    backgroundColor: '#3a3a3a'
                                }}
                                className="file-upload-input"
                                component="label"
                                variant="contained"
                                startIcon={<FontAwesomeIcon icon={faCloudArrowUp} />}
                                href="#file-upload"
                                disabled={fetchingFile}
                                >
                                    Select{resumeFile.name && 'ed'} File  {resumeFile.name ? ': ' + resumeFile.name : ''}
                                    <Input
                                        className="hidden-input"
                                        type="file"
                                        onChange={handleFileChange}
                                        inputProps={{ accept: '.pdf,.doc,.docx' }} // Specify allowed file types
                                    />
                                </Button>
                                <br />
                                {
                                    resumeFile.name && !fetchingFile &&
                                    <Button 
                                        className="file-submit-button"
                                        variant="contained" 
                                        component="span" 
                                        onClick={getFileText} 
                                        disabled={fetchingFile}>
                                        Submit
                                    </Button>
                                }
                            </div>
                            <br />
                            <div className="w90 left-text">
                                {

                                    fetchingFile ?
                                    <div className="loading-message">
                                        <LinearProgress color="inherit" />
                                        <br />
                                        <h3>Processing ...</h3>
                                        <div>This could take some time. Please be patient. Do not refresh the page.</div>
                                    </div> :
                                    <>
                                    { workExList.length > 0 &&
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={6} >
                                                {<><b>Select Relevant Work Experience</b><br /><br /></>}
                                                {
                                                    workExList.map((key: any) => {
                                                        return (
                                                            <WorkSelectInput 
                                                                key={key.index}
                                                                selected={selectedWorkEx === key.index}
                                                                workExObject={key}
                                                                onClick={handleRadioChange} />
                                                            
                                                        )
                                                    })
                                                }
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <div className="w90 original-work-preview">
                                                    <h6>Preview Original Work Experience</h6>
                                                    <p>{workExList[selectedWorkEx].description}</p>
                                                </div>
                                            </Grid>
                                        </Grid>    
                                    }
                                    </>
                                }
                            </div>
                            <br />
                            <div className="w90 left-text">
                                <div className="button-container">
                                    <Button size={'small'} variant="contained" className="morph-step-button" onClick={() => setView(TryItOutStep.INTRO)}>
                                        &larr; Back
                                    </Button>

                                    {
                                        fileText.length > 0 && !fetchingFile ?
                                        <Button size={'small'} variant="contained" className="morph-step-button" onClick={() => setView(TryItOutStep.CHOOSE_OPTION)}>
                                            Next &rarr;
                                        </Button> :
                                        <span className="step-message">Please upload your resume</span>
                                    }
                                </div>
                            </div>
                        </> :
                        <>
                            <div className="w90 left-text">
                                <TextField className="jd-form" id="outlined-basic" label="Enter your designation" variant="standard" value={workExTitle} onChange={(e) => setWorkExTitle(e.currentTarget.value)} />
                                <br />
                                <TextField className="jd-form textarea" id="outlined-basic" label="Job Description" variant="standard" rows={4} value={workExDesc} onChange={(e) => setWorkExDesc(e.currentTarget.value)} multiline/>
                                <br />
                                <br />
                                <h6>Skills</h6>
                                {
                                    skills.length > 0 ?
                                    <div className="skills-tag-container">
                                        {
                                            skills.map((sk: string) => {
                                                return (
                                                    <span className="skills-tag">{sk} <span className="red" onClick={() => {
                                                        setSkills(skills.filter((sk1) => sk1 !== sk));
                                                    }}>x</span></span>   
                                                )
                                            })
                                        }
                                    </div> :
                                    <span className="step-message">Please add at leaset 1 skill to continue</span>
                                }
                                <br />
                                <br />
                                <TextField className="jd-form" id="outlined-basic" label="Enter a skill and hit enter to add it to your list of skills" 
                                    variant="standard" value={skill} onChange={(e) => setSkill(e.currentTarget.value)} onKeyUp={addSkill} />
                                <br />
                            </div>
                            <br />
                            <div className="w90 left-text">
                                <div className="button-container">
                                    <Button size={'small'} variant="contained" className="morph-step-button" onClick={() => setView(TryItOutStep.INTRO)}>
                                        &larr; Back
                                    </Button>

                                    {
                                        validWorkEx ?
                                        <Button size={'small'} variant="contained" className="morph-step-button" onClick={() => setView(TryItOutStep.CHOOSE_OPTION)}>
                                            Next &rarr;
                                        </Button> :
                                        <span className="step-message">Please enter the details of your work experience</span>
                                    }
                                </div>
                            </div>
                        </>
                    }  
                    </> :
                    view === TryItOutStep.CHOOSE_OPTION ?
                    <>
                        <div className="w90">
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={4} >
                                    <div className={trialButtonOptionStyles.boost} onClick={() => setOption(TrialOption.BOOST)}>
                                        <BoltSharp />
                                        <span className="home-nav-button-title">Boost Resume</span>
                                        <span className="home-nav-button-description">Reword your work experience to better fit a job opening that you're applying for</span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <div className={trialButtonOptionStyles.cover} onClick={() => setOption(TrialOption.COVER)}>
                                        <ContactPageSharp />
                                        <span className="home-nav-button-title">Generate Cover Letter</span>
                                        <span className="home-nav-button-description">Write a personalized cover letter tailored to a job opening</span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <div className={trialButtonOptionStyles.cold} onClick={() => setOption(TrialOption.COLD)}>
                                        <ReviewsSharp />
                                        <span className="home-nav-button-title">Generate Cold Message</span>
                                        <span className="home-nav-button-description">Catch the recruiter's attention with a cold message </span>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                        <br />
                        <div className="w90 left-text">
                            <div className="button-container">
                                <Button size={'small'} variant="contained" className="morph-step-button" onClick={() => setView(TryItOutStep.PDF)}>
                                    &larr; Back
                                </Button>
                                
                                {
                                    option != null && option != undefined ?
                                    <Button size={'small'} variant="contained" className="morph-step-button" onClick={() => setView(TryItOutStep.ENTER_JD)}>
                                        Next &rarr;
                                    </Button> :
                                    <span className="step-message">Please choose the tool you want to try</span>
                                }

                            </div>
                        </div>
                    </> :
                    view === TryItOutStep.ENTER_JD ?
                    <>
                        <div className="w90 left-text">
                            <TextField className="jd-form" id="outlined-basic" label="Job Title" variant="standard" value={jobTitle} onChange={(e) => setJobTitle(e.currentTarget.value)} />
                            <br />
                            <TextField className="jd-form textarea" id="outlined-basic" label="Job Description" variant="standard" rows={4} value={jobDesc} onChange={(e) => setJobDesc(e.currentTarget.value)} multiline/>
                            <br />
                            <TextField className="jd-form" id="outlined-basic" label="Required Qualifications" variant="standard" rows={4} value={jobReq} onChange={(e) => setJobReq(e.currentTarget.value)} multiline/>
                            <br />
                            <div className="button-container">
                                <Button size={'small'} variant="contained" className="morph-step-button" onClick={() => setView(TryItOutStep.CHOOSE_OPTION)}>
                                    &larr; Back
                                </Button>
                                {
                                    validJd ?
                                    <Button size={'small'} variant="contained" className="morph-step-button" onClick={submitFunction}>
                                        Submit &rarr;
                                    </Button> :
                                    <span className="step-message">Please enter the details of the job opening</span>
                                }
                            </div>
                        </div>
                    </> :
                    view === TryItOutStep.RESULT ?
                    <>
                        <div className="w90 result-preview">
                            <h4>Result</h4>
                            <div>
                                {/* {JSON.stringify(result)} */}
                                {
                                    option === TrialOption.BOOST ?
                                    <ul>
                                        {/* {
                                            result.map((point: any) => {
                                                return <li>{point}</li>
                                            })
                                        } */}
                                        {result}
                                    </ul> :
                                    <p>{ result }</p>
                                }
                            </div>
                            <br />
                            <Button size={'small'} variant="outlined" className="copy" onClick={copyResult}>Copy Result</Button>
                            <Button size={'small'} variant="contained" className="morph-step-button" onClick={submitFunction}>
                                Regenerate
                            </Button>
                        </div>
                        <br />
                        <div className="w90 left-text">
                            <div className="button-container">
                                <Button size={'small'} variant="contained" className="morph-step-button" onClick={() => setView(TryItOutStep.CHOOSE_OPTION)}>
                                    &larr; Back
                                </Button>

                                <div></div>
                            </div>
                        </div>
                    </> :
                    <></>
                }
            </div>
        </div>
    )
}

export default TryItOut;