import { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ProfileView } from "../constants/Constants";
import { Context } from "../GlobalStore";
import LoggedInTopBar from "./LoggedInTopBar";
import EducationCard from "./profileChildComponents/EducationCard";
import ProfileViewButton from "./profileChildComponents/ProfileViewButton";
import WorkExCard from "./profileChildComponents/WorkExCard";

const Profile = () => {

    const navigate = useNavigate();

    const {store, dispatch} = useContext(Context);

    const [view, setView] = useState(ProfileView.WORK_EX);

    const profileCompleteness = {
        education: store.user.education.length > 0 ? 1 : 0,
        workExperience: store.user.workExperience.length > 0 ? 1 : 0,
        skills: store.user.skills.length > 0 ? 1 : 0
    }

    const total = profileCompleteness.education + profileCompleteness.workExperience + profileCompleteness.skills;
    const percentage = total / 3 * 100;
    const color = percentage === 100 ? "green" : percentage > 0 ? "yellow" : "red";

    const profileProgressClasses = [...Array(total).fill("bg-black1"), ...Array(3-total).fill("bg-gray1")];

    return (
        <div className="App">
            <LoggedInTopBar />
            < br/>
            <div className="w90 left-text">
                <h3>My Profile</h3>
            </div>
            <br />
            <div className="w90 left-text profile-completeness">
                <span>Your profile is <span className={color}>{percentage} %</span> complete!</span>
                <br />
                <div className="profile-progress">
                    <div className={profileProgressClasses[0]}></div>
                    <div className={profileProgressClasses[1]}></div>
                    <div className={profileProgressClasses[2]}></div>
                </div>
            </div>
            <br />
            <div className="w90">
                <div className="profile-top-bar">
                    <div className="profile-view-bar">
                        <ProfileViewButton selected={view === ProfileView.WORK_EX} onClick={setView} value={ProfileView.WORK_EX} label={"Work"} />
                        <ProfileViewButton selected={view === ProfileView.EDUCATION} onClick={setView} value={ProfileView.EDUCATION} label={"Education"} />
                        <ProfileViewButton selected={view === ProfileView.SKILLS} onClick={setView} value={ProfileView.SKILLS} label={"Skills"} />
                    </div>
                    <div>

                    </div>
                </div>
            </div>
            <br/>
            {
                view === ProfileView.WORK_EX ?
                <div className="w90 profile-content">
                    <h5>Work Experience</h5>
                    <div>
                        {
                            store.user.workExperience.map((key: any) => {
                                return <WorkExCard workExObject={key} />
                            })
                        }
                    </div>
                    <Link to={"/updateWorkExperience"}>Edit</Link>
                </div> :
                view === ProfileView.EDUCATION ?
                <div className="w90 profile-content">
                    <h5>Education</h5>
                    <div>
                        {
                            store.user.education.map((key: any) => {
                                return <EducationCard eduObject={key} />
                            })
                        }
                    </div>
                    <Link to={"/updateEducation"}>Edit</Link>
                </div> :
                <div className="w90 profile-content">
                    <h5>Skills</h5>
                    <div className="skills-tag-container">
                        {
                            store.user.skills.map((skill: string) => {
                                return (
                                    <span className="skills-tag">{skill}</span>   
                                )
                            })
                        }
                    </div>
                    <br />
                    <Link to={"/updateSkills"}>Edit</Link>
                </div>
            }
        </div>

    )
}

export default Profile;