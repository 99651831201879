import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LoadingButton } from "@mui/lab";
import { Grid, TextField, Button } from "@mui/material";
import { useContext, useState } from "react";
import { setOnboardingStepAction, addEducationOnboardingAction, addWorkExOnboardingAction, updateWorkExOnboardingAction } from "../../actions/Actions";
import { OnboardingStep } from "../../constants/Constants";
import { Context } from "../../GlobalStore";
import WorkExCard from "../profileChildComponents/WorkExCard";
import _ from "lodash";

const WorkEx = () => {
    const {store, dispatch} = useContext(Context);

    const [workExObj, setworkExObj] = useState({
        designation: "",
        company: "",
        description: "",
        startDate: "",
        endDate: "",
        currentlyEmployed: false
    });

    const [view, setView] = useState(0);

    const removeWorkEx = (keyObj: any) => {
        const we1 = store.onboarding.workExperience.filter((item) => !(_.isEqual(keyObj, item)));
        dispatch(updateWorkExOnboardingAction(we1));
    }

    return (
        <>
            <div className="w90">
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} >
                        <br />
                        <TextField className="jd-form" id="outlined-basic" label="Company" variant="standard" value={workExObj.company}
                            onChange={(e) => setworkExObj({...workExObj, company: e.currentTarget.value})} />
                        <br />
                        <TextField className="jd-form" id="outlined-basic" label="Designation" variant="standard" value={workExObj.designation} 
                            onChange={(e) => setworkExObj({...workExObj, designation: e.currentTarget.value})}/>
                        <br />
                        <TextField className="jd-form" id="outlined-basic" label="Description" variant="standard" value={workExObj.description} 
                            onChange={(e) => setworkExObj({...workExObj, description: e.currentTarget.value})} minRows={4} multiline/>
                        <br />
                        <TextField className="jd-form" id="outlined-basic" type={"date"} label="Start Date" variant="standard" value={workExObj.startDate} 
                            onChange={(e) => setworkExObj({...workExObj, startDate: e.currentTarget.value})} focused/>
                        <br />
                        {
                            !workExObj.currentlyEmployed && 
                            <>
                            <TextField className="jd-form" id="outlined-basic" type={"date"} label="End Date" variant="standard" value={workExObj.endDate} 
                                onChange={(e) => setworkExObj({...workExObj, endDate: e.currentTarget.value})} focused/>
                            <br />
                            </>
                        }
                        
                        <div className="form-check checkbox-div">
                            <input className="form-check-input" type="checkbox" value="" id="activeEmployment" 
                                onChange={(e) => setworkExObj({...workExObj, currentlyEmployed: e.currentTarget.checked, endDate: ''})}  />
                            <label className="form-check-label" htmlFor="activeEmployment">
                                Currently Employed
                            </label>
                        </div>
                        <br />
                        <div className="button-container">
                            <Button size={'small'} variant="outlined" className="morph-step-button-outlined" onClick={() => {
                                dispatch(addWorkExOnboardingAction(workExObj));
                            }}>
                                + Add
                            </Button>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6} >
                        <div className="left-text">Preview</div>
                        {
                        store.onboarding.workExperience.length === 0 ?
                        <div className="no-work">
                            <span>No New Experiences</span>
                        </div> :
                        <div className="full-width left-text">
                            {
                               store.onboarding.workExperience.map((key: any) => {
                                    return <WorkExCard workExObject={key} edit={1} editFunction={removeWorkEx} />
                                })
                            }
                        </div>
                        }    
                    </Grid> 
                </Grid>
                <br />
                <div className="button-container">
                    <Button size={'small'} variant="contained" className="morph-step-button" onClick={() => dispatch(setOnboardingStepAction(OnboardingStep.EDUCATION))}>
                        &larr; Back
                    </Button>
                    <Button size={'small'} variant="contained" className="morph-step-button" onClick={() => dispatch(setOnboardingStepAction(OnboardingStep.SKILLS))}>
                        {
                            store.onboarding.workExperience.length > 0 ?
                            "Next" :
                            "Skip"
                        } 
                        &rarr;
                    </Button>
                </div>
            </div>
        </>
    )
}

export default WorkEx;