import { OnboardingStep, TryItOutMode } from "../constants/Constants";

export enum Actions {
    SIGN_IN = "SIGN_IN",
    ONBOARD_USER = "ONBOARD_USER",
    COMPLETE_ONBOARDING = "COMPLETE_ONBOARDING",

    SET_ONBOARDING_MODE = "SET_ONBOARDING_MODE",
    SET_ONBOARDING_STEP = "SET_ONBOARDING_STEP",
    ADD_EDUCATION = "ADD_EDUCATION",
    ADD_WORK_EX = "ADD_WORK_EX",
    ADD_SKILL = "ADD_SKILL",

    UPDATE_EDUCATION = "UPDATE_EDUCATION",
    UPDATE_WORK_EX = "UPDATE_WORK_EX",
    UPDATE_SKILLS = "UPDATE_SKILLS",

    SUBMIT_ONBOARDING_DETAILS = "SUBMIT_ONBOARDING_DETAILS",

    UPDATE_USER = "UPDATE_USER",

    RESET = "RESET",

    GET_PDF_TEXT = "GET_PDF_TEXT"
}

export const signInAction = (user: Object, firebaseUser: Object) => ({
    type: Actions.SIGN_IN,
    payload: {
        user,
        firebaseUser
    }
});

export const onboardUserAction = (firebaseUser: Object) => ({
    type: Actions.ONBOARD_USER,
    payload: firebaseUser
});

export const completeOnboardingAction = (user: Object) => ({
    type: Actions.COMPLETE_ONBOARDING,
    payload: user
});

// Onboarding actions
export const setOnboardingMode = (mode: TryItOutMode) => ({
    type: Actions.SET_ONBOARDING_MODE,
    payload: mode
});

export const setOnboardingStepAction = (step: OnboardingStep) => ({
    type: Actions.SET_ONBOARDING_STEP,
    payload: step
});

export const addEducationOnboardingAction = (education: any) => ({
    type: Actions.ADD_EDUCATION,
    payload: education
});

export const addWorkExOnboardingAction = (workEx: any) => ({
    type: Actions.ADD_WORK_EX,
    payload: workEx
});

export const addSkillOnboardingAction = (skill: any) => ({
    type: Actions.ADD_SKILL,
    payload: skill
});

export const updateEducationOnboardingAction = (education: any) => ({
    type: Actions.UPDATE_EDUCATION,
    payload: education
});

export const updateWorkExOnboardingAction = (workEx: any) => ({
    type: Actions.UPDATE_WORK_EX,
    payload: workEx
});

export const updateSkillsOnboardingAction = (skill: any) => ({
    type: Actions.UPDATE_SKILLS,
    payload: skill
});

export const updateUserAction = (newUser: any) => ({
    type: Actions.UPDATE_USER,
    payload: newUser
});

export const resetAction = () => ({
    type: Actions.RESET
});

// PDF Actions
export const getPDFTextAction = (pdf: File) => ({
    type: Actions.GET_PDF_TEXT,
    payload: pdf
});

