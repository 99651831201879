import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LoadingButton } from "@mui/lab";
import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Toolbar, Typography } from "@mui/material";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { updateUserAction } from "../../actions/Actions";
import { updateEducation } from "../../api/FetchDataService";
import { EducationLevels } from "../../constants/Constants";
import { Context } from "../../GlobalStore";
import LoggedInTopBar from "../LoggedInTopBar";
import EducationCard from "./EducationCard";
import _ from "lodash";

const initialState = {
    level: "MASTERS",
    schoolName: "",
    discipline: "",
    startDate: "",
    endDate: "",
    currentlyEnrolled: false
}

const UpdateEducation = () => {

    const navigate = useNavigate();

    const {store, dispatch} = useContext(Context);

    const [educationObj, setEducationObj] = useState(initialState);

    const [edu, setEdu] = useState([...store.user.education] as any);

    const [view, setView] = useState(0);

    const [loading, setLoading] = useState(false);

    const saveNewEducation = async () => {
        setLoading(true);
        const res = await updateEducation(store.user.userId, edu);
        if (res.error) {
            alert("Error");
            setLoading(false);
        } else {
            dispatch(updateUserAction({...store.user, education: [...edu] }));
            navigate('/profile');
        }
    }

    const removeEdu = (keyObj: any) => {
        const edu1 = edu.filter((item: any) => !(_.isEqual(keyObj, item)));
        setEdu(edu1);
    }
    
    return (
        <div className="App">
            <LoggedInTopBar />
            <br />
            <div className="w90 left-text">
                <h4>Update Education</h4>
            </div>

            <br />

            <div className="w90">
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} >
                        <br />
                        <FormControl className="select-container left-text" fullWidth>
                            <InputLabel className="left-text" id="demo-simple-select-label">Level</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={educationObj.level}
                                label="Level"
                                onChange={(e) => setEducationObj({...educationObj, level: e.target.value})}
                            >   
                                {
                                    Object.keys(EducationLevels).map((key) => {
                                        return (
                                            <MenuItem key={key} className="left-text" value={key}>{EducationLevels[key]}</MenuItem>
                                        )
                                    })
                                }
                            </Select>
                        </FormControl>
                        <br />
                        <TextField className="jd-form" id="outlined-basic" label="Discipline" variant="standard" value={educationObj.discipline}
                           onChange={(e) => setEducationObj({...educationObj, discipline: e.currentTarget.value})} />
                        <br />
                        <TextField className="jd-form" id="outlined-basic" label="School Name" variant="standard" value={educationObj.schoolName} 
                            onChange={(e) => setEducationObj({...educationObj, schoolName: e.currentTarget.value})}/>
                        <br />
                        <TextField className="jd-form" id="outlined-basic" type={"date"} label="Start Date" variant="standard" value={educationObj.startDate} 
                            onChange={(e) => setEducationObj({...educationObj, startDate: e.currentTarget.value})} focused/>
                        <br />
                        {
                            !educationObj.currentlyEnrolled && 
                            <>
                            <TextField className="jd-form" id="outlined-basic" type={"date"} label="End Date" variant="standard" value={educationObj.endDate} 
                                onChange={(e) => setEducationObj({...educationObj, endDate: e.currentTarget.value})} focused/>
                            <br />
                            </>
                        }
                        
                        <div className="form-check checkbox-div">
                            <input className="form-check-input" type="checkbox" value="" id="activeEnrollment" 
                                onChange={(e) => setEducationObj({...educationObj, currentlyEnrolled: e.currentTarget.checked, endDate: ''})}  />
                            <label className="form-check-label" htmlFor="activeEmployment">
                                Currently Enrolled
                            </label>
                        </div>

                        <br />
                        <div className="button-container">
                            <Button size={'small'} variant="outlined" className="morph-step-button-outlined" onClick={() => {
                                setEdu([...edu, educationObj]);
                                setEducationObj(initialState);
                            }}>
                                + Add
                            </Button>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6} >
                        <div className="left-text">Preview</div>
                        {
                        edu.length === 0 ?
                        <div className="no-work">
                            <span>No New Education</span>
                        </div> :
                        <div className="full-width left-text">
                            {
                               edu.map((key: any) => {
                                    return <EducationCard key={key} eduObject={key} edit={1} editFunction={removeEdu} />
                                })
                            }
                            {
                                loading ?
                                <LoadingButton
                                loading
                                startIcon={<FontAwesomeIcon icon={faCircle} />}
                                loadingPosition="start"
                                variant="outlined"
                                >
                                    Loading
                                </LoadingButton> :
                                <Button size={'small'} variant="contained" className="morph-step-button" onClick={saveNewEducation}>
                                    Save Education &rarr;
                                </Button>

                            }
                        </div>
                        }
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}

export default UpdateEducation;