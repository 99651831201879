import { createContext, Dispatch } from "react";
import { Actions } from "./actions/Actions";
import { OnboardingStep, PDFOnboardingStep, TryItOutMode } from "./constants/Constants";

export interface AppState {
    loggedIn: boolean;
    user: any;
    firebaseUser: any;
    onboarded: boolean;
    onboarding: {
        education: string[];
        skills: string[];
        workExperience: {
            title: string;
            company: string;
            startDate: string;
            endDate: string;
            active: boolean;
            description: string;
        }[];
        step: OnboardingStep | PDFOnboardingStep;
        mode: TryItOutMode;
        pdfText: string;
        pdfExtract: {
            education: string[];
            skills: string[];
            workExperience: {
                title: string;
                company: string;
                startDate: string;
                endDate: string;
                active: boolean;
                description: string;
            }[];
        }
    }
}

export interface Action { 
    type: string;
    payload?: any;
}

export interface AppContext {
    store: AppState;
    dispatch: Dispatch<Action>;
}

export const initialState: AppState = {
    loggedIn: false,
    user: {},
    firebaseUser: {},
    onboarded: false,
    onboarding: {
        education: [],
        skills: [],
        workExperience: [],
        step: PDFOnboardingStep.GET_STARTED,
        mode: TryItOutMode.PDF,
        pdfText: '',
        pdfExtract: {
            workExperience: [],
            skills: [],
            education: []
        }
    }
};

export const reducer = (state: AppState, action: Action) => {
    switch(action.type) {
        case Actions.SIGN_IN:
            return { ...state, loggedIn: true, firebaseUser: action.payload.firebaseUser, user: action.payload.user, onboarded: true }
        case Actions.ONBOARD_USER:
            return { ...state, loggedIn: true, firebaseUser: action.payload, onboarded: false }
        case Actions.COMPLETE_ONBOARDING:
            return { ...state, user: action.payload, onboarded: true, onboarding: initialState.onboarding }
        case Actions.SET_ONBOARDING_MODE:
            return { ...state, onboarding: { ...state.onboarding, mode: action.payload }}
        case Actions.SET_ONBOARDING_STEP:
            return { ...state, onboarding: { ...state.onboarding, step: action.payload }}
        case Actions.ADD_EDUCATION:
            return { ...state, onboarding: { ...state.onboarding, education: [...state.onboarding.education, action.payload] } }
        case Actions.ADD_WORK_EX:
            return { ...state, onboarding: { ...state.onboarding, workExperience: [...state.onboarding.workExperience, action.payload] } }
        case Actions.ADD_SKILL:
            return { ...state, onboarding: { ...state.onboarding, skills: [...state.onboarding.skills, action.payload] } }
        case Actions.UPDATE_EDUCATION:
            return { ...state, onboarding: { ...state.onboarding, education: [...action.payload] } }
        case Actions.UPDATE_WORK_EX:
            return { ...state, onboarding: { ...state.onboarding, workExperience: [...action.payload] } }
        case Actions.UPDATE_SKILLS:
            return { ...state, onboarding: { ...state.onboarding, skills: [...action.payload] } }
        case Actions.UPDATE_USER:
            return { ...state, user: action.payload }
        case Actions.RESET:
            return initialState;
        default:
            return state;
    }
}

export const Context = createContext({ store: initialState, dispatch: () => {}} as AppContext);