import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LoadingButton } from "@mui/lab";
import { Grid, Button } from "@mui/material";
import { useContext, useState } from "react";
import { completeOnboardingAction, setOnboardingStepAction } from "../../actions/Actions";
import { signUpUser } from "../../api/FetchDataService";
import { OnboardingStep } from "../../constants/Constants";
import { Context } from "../../GlobalStore";
import EducationCard from "../profileChildComponents/EducationCard";
import WorkExCard from "../profileChildComponents/WorkExCard";

const Review = () => {

    const {store, dispatch} = useContext(Context);

    const [loading, setLoading] = useState(false);

    const signUp = async () => {
        setLoading(true);
        try {
            const result = await signUpUser(
                store.firebaseUser.user.uid,
                store.firebaseUser.user.email,
                store.onboarding.workExperience,
                store.onboarding.education,
                store.onboarding.skills
            );

            if (result) {
                dispatch(completeOnboardingAction(result));
            } else {
                throw Error("Result is empty");
            }

        } catch (e) {
            console.log("Error Signing Up");
            console.log(e);
            setLoading(false);
        }
    }

    return (
        <>
            <div className="w90 left-text">
                <h4>Review</h4>
            </div>

            <br />

            <div className="w90">
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} >
                    <div className="full-width left-text">
                        <div className="review-label">Education</div>
                        {   
                            store.onboarding.education.length > 0 ?
                            store.onboarding.education.map((key: any) => {
                                return <EducationCard eduObject={key} />
                            }) :
                            <div className="no-work">
                                No Education
                            </div>
                        }
                    </div>
                    <br />
                    <div className="full-width left-text">
                        <div className="review-label">Work Experience</div>
                        {   
                            store.onboarding.workExperience.length > 0 ?
                            store.onboarding.workExperience.map((key: any) => {
                                return <WorkExCard workExObject={key} />
                            }) :
                            <div className="no-work">
                                No Work Experience
                            </div>
                        }
                    </div>
                    <br />
                    <div className="skills-tag-container left-text">
                        <div className="review-label">Skills</div>
                        {   
                            store.onboarding.skills.length > 0 ?
                            store.onboarding.skills.map((key: any) => {
                                return <span className="skills-tag">{key}</span>
                            }) :
                            <div className="no-work">
                                No Skills
                            </div>
                        }
                    </div>
                    </Grid>
                </Grid>
                <br />
                <div className="button-container">
                    <Button size={'small'} disabled={loading} variant="contained" className="morph-step-button" onClick={() => dispatch(setOnboardingStepAction(OnboardingStep.SKILLS))}>
                            &larr; Back
                    </Button>
                    {
                        loading ?
                        <LoadingButton
                        loading
                        startIcon={<FontAwesomeIcon icon={faCircle} />}
                        loadingPosition="start"
                        variant="outlined"
                        >
                            Loading
                        </LoadingButton> :
                        <Button size={'small'} variant="contained" className="morph-step-button" onClick={signUp}>
                            Sign Up &rarr;
                        </Button>

                    }
                </div>
            </div>
        </>
    )
}

export default Review;