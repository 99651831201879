const StepIndicator = (props: { [key: string]: any }) => {

    const {
        steps,
        activeStep
    } = props;

    return (
        <div className="step-indicator w90">
            <div className="step-indicator-number">{activeStep + 1}</div>
            <div> | </div>
            <div className="step-indicator-text">
            {
                steps.map((currentElement: string, index: number) => {
                    if (activeStep === index) {
                        return currentElement;
                    } 
                    return "";
                })
            }
            </div>
        </div>
    )
}

export default StepIndicator;