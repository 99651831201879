import { faBars, faBolt, faHamburger } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AppBar, Toolbar, IconButton, Typography, Grid, Button } from "@mui/material";
import { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Context } from "../GlobalStore";
import Cool from "./Cool";
import boost from "../static/boost.png";
import profile from "../static/profile.png";
import LoggedInTopBar from "./LoggedInTopBar";
import { BoltSharp, ContactPageSharp, ReviewsSharp } from "@mui/icons-material";

const Home = () => {

    const {store, dispatch} = useContext(Context);

    const navigate = useNavigate();

    return (
        <div className="App">
            <LoggedInTopBar />
            <br />
            <div className="welcome">
                <h5>Hello,</h5>
                <h1>{store.firebaseUser.user.displayName}</h1>
            </div> 
            <br />
            <div className="w90 p2s">
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={4} >
                        <div className="home-nav-button redbox" onClick={() => navigate('/morph')}>
                            <BoltSharp />
                            <span className="home-nav-button-title">Boost Resume</span>
                            <span className="home-nav-button-description">Reword your work experience to better fit a job opening that you're applying for</span>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <div className="home-nav-button redbox" onClick={() => navigate('/cover')}>
                            <ContactPageSharp />
                            <span className="home-nav-button-title">Generate Cover Letter</span>
                            <span className="home-nav-button-description">Write a personalized cover letter tailored to a job opening</span>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <div className="home-nav-button redbox" onClick={() => navigate('/cold')}>
                            <ReviewsSharp />
                            <span className="home-nav-button-title">Generate Cold Message</span>
                            <span className="home-nav-button-description">Catch the recruiter's attention with a cold message </span>
                        </div>
                    </Grid>
                </Grid>
            </div>
            
        </div>
    );
}

export default Home;