import { useState } from "react";
import { Education, EducationLevels } from "../../constants/Constants";

const EducationCard = (props: {eduObject: Education, edit?: number | undefined, editFunction?: Function | undefined}) => {

    const {
        level,
        discipline,
        schoolName,
        startDate,
        endDate,
        currentlyEnrolled
    } = props.eduObject;

    const [view, setView] = useState(false);

    const toggleView = () => {
        setView(!view);
    }

    const className = view ? "work-ex-card work-ex-card-active" : "work-ex-card";

    const remove = (e: any) => {
        e.preventDefault();
        props.editFunction?.(props.eduObject);
    }

    return (
        <div className={className} onClick={toggleView}>
            <div className="work-ex-card-company">{schoolName}</div>
            <div className="work-ex-card-designation">{EducationLevels[level]}, {discipline}</div>
            <div className="work-ex-card-date">
                {startDate} to {
                    currentlyEnrolled ?
                    "Present" :
                    endDate
                }
            </div>
            <br />
            {
                props.edit && <span onClick={remove} className="remove-button">Remove</span>
            }
        </div>
    )
}

export default EducationCard;