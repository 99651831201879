const BASE_URL = "https://asia-south1-resumorph.cloudfunctions.net/getPDFText";

export const getPDFText = async (pdf: File) => {
    const formData = new FormData();
    formData.append("file", pdf);

    try {
        const response = await fetch(`${BASE_URL}`, {
            method: 'post',
            body: formData
        });

        const data = await response.json();
        console.log("PDF Service Response: ", data);

        return data;
    } catch (e) {
        console.log("Error: ", e);
        throw e;
    }
}