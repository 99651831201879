import { TryItOutMode } from "../../constants/Constants";

const OnboardingModeSelector = (props: {
    selected: boolean,
    label: string,
    onClick: any,
    value: TryItOutMode
}) => {

    const {selected, label, onClick, value} = props;

    const className = selected ? "profile-view-select-button-selected profile-view-select-button" : "profile-view-select-button";

    return (
        <div className={className} onClick={() => onClick(value)}>
            {label}
        </div>
    )
}

export default OnboardingModeSelector;