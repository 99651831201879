import { AppBar, Toolbar, Typography, Button } from "@mui/material"
import { signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { auth } from "../utils/firebaseInit";

const Component = () => {

    const navigate = useNavigate();

    return (
        <AppBar position="static" className="appbar">
            {/* <Toolbar variant="dense">
                <Typography variant="h6" color="inherit" component="div">
                    
                </Typography>
            </Toolbar> */}
            <div className="logo bar-logo" onClick={() => navigate('/')}>boosted.fyi</div>

            <div className="nav-bar-button-container">
                <Button className="nav-bar-button" size="small" onClick={() => {
                    navigate('/profile');
                }}>Profile</Button>

                <Button className="nav-bar-button" size="small" onClick={() => {
                    signOut(auth);
                }}>Sign Out</Button>
            </div>
        </AppBar>
    )
}

export default Component;