const WorkSelectInput = (props: {
    workExObject: any,
    selected: boolean,
    onClick: Function
}) => {

    const {workExObject, selected, onClick} = props;

    const className = selected ? "work-select-input work-select-input-selected" : "work-select-input";

    return (
        <div className={className} onClick={() => onClick({ currentTarget: { value: workExObject.index }})}>
            <div className="select-indicator">
            </div>
            <div className="select-label">
                <div className="select-label-company">{workExObject.company}</div>
                <div className="select-label-designation">{workExObject.designation}</div>
            </div>
        </div>
    )
}

export default WorkSelectInput;