import { Grid, FormControl, InputLabel, Select, MenuItem, TextField, Button } from "@mui/material";
import { useContext, useState } from "react";
import { addEducationOnboardingAction, setOnboardingStepAction, updateEducationOnboardingAction } from "../../actions/Actions";
import { EducationLevels, OnboardingStep } from "../../constants/Constants";
import { Context } from "../../GlobalStore";
import EducationCard from "../profileChildComponents/EducationCard";
import _ from "lodash";

const Education = () => {

    const {store, dispatch} = useContext(Context);

    const [educationObj, setEducationObj] = useState({
        level: "MASTERS",
        schoolName: "",
        discipline: "",
        startDate: "",
        endDate: "",
        currentlyEnrolled: false
    });

    const removeEdu = (keyObj: any) => {
        const edu1 = store.onboarding.education.filter((item) => !(_.isEqual(keyObj, item)));
        dispatch(updateEducationOnboardingAction(edu1));
    }

    const [view, setView] = useState(0);

    return (
        <>
            <div className="w90">
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} >
                        <br />
                        <FormControl className="select-container left-text" fullWidth>
                            <InputLabel className="left-text" id="demo-simple-select-label">Level</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={educationObj.level}
                                label="Level"
                                onChange={(e) => setEducationObj({...educationObj, level: e.target.value})}
                            >   
                                {
                                    Object.keys(EducationLevels).map((key) => {
                                        return (
                                            <MenuItem className="left-text" value={key}>{EducationLevels[key]}</MenuItem>
                                        )
                                    })
                                }
                            </Select>
                        </FormControl>
                        <br />
                        <TextField className="jd-form" id="outlined-basic" label="Discipline" variant="standard" value={educationObj.discipline}
                           onChange={(e) => setEducationObj({...educationObj, discipline: e.currentTarget.value})} />
                        <br />
                        <TextField className="jd-form" id="outlined-basic" label="School Name" variant="standard" value={educationObj.schoolName} 
                            onChange={(e) => setEducationObj({...educationObj, schoolName: e.currentTarget.value})}/>
                        <br />
                        <TextField className="jd-form" id="outlined-basic" type={"date"} label="Start Date" variant="standard" value={educationObj.startDate} 
                            onChange={(e) => setEducationObj({...educationObj, startDate: e.currentTarget.value})} focused/>
                        <br />
                        {
                            !educationObj.currentlyEnrolled && 
                            <>
                            <TextField className="jd-form" id="outlined-basic" type={"date"} label="End Date" variant="standard" value={educationObj.endDate} 
                                onChange={(e) => setEducationObj({...educationObj, endDate: e.currentTarget.value})} focused/>
                            <br />
                            </>
                        }
                        
                        <div className="form-check checkbox-div">
                            <input className="form-check-input" type="checkbox" value="" id="activeEnrollment" 
                                onChange={(e) => setEducationObj({...educationObj, currentlyEnrolled: e.currentTarget.checked, endDate: ''})}  />
                            <label className="form-check-label" htmlFor="activeEmployment">
                                Currently Enrolled
                            </label>
                        </div>

                        <br />
                        <div className="button-container">
                            <Button size={'small'} variant="outlined" className="morph-step-button-outlined" onClick={() => {
                                dispatch(addEducationOnboardingAction(educationObj));
                            }}>
                                + Add
                            </Button>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6} >
                        <div className="left-text">Preview</div>
                        {
                        store.onboarding.education.length === 0 ?
                        <div className="no-work">
                            <span>No New Education</span>
                        </div> :
                        <div className="full-width left-text">
                            {
                               store.onboarding.education.map((key: any) => {
                                    return <EducationCard eduObject={key} key={key} edit={1} editFunction={removeEdu} />
                                })
                            }
                        </div>
                        }
                        <br />
                    </Grid>
                </Grid>
                <br />
                <div className="button-container">
                    <Button size={'small'} variant="contained" className="morph-step-button" onClick={() => dispatch(setOnboardingStepAction(OnboardingStep.GET_STARTED))}>
                        &larr; Back
                    </Button>
                    <Button size={'small'} variant="contained" className="morph-step-button" onClick={() => dispatch(setOnboardingStepAction(OnboardingStep.WORK_EXPERIENCE))}>
                        {
                            store.onboarding.education.length > 0 ?
                            "Next" :
                            "Skip"
                        } 
                        &rarr;
                    </Button>
                </div>
            </div>
        </>
        
    )
}

export default Education;