import { useEffect, useReducer, useState } from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import SignIn from './components/SignIn';
import { Context, initialState, reducer } from './GlobalStore';
import Home from './components/Home';
import OnboardUser from './components/OnboardUser';
import Morph from './components/Morph';
import Profile from './components/Profile';
import UpdateEducation from './components/profileChildComponents/UpdateEducation';
import UpdateWorkExperience from './components/profileChildComponents/UpdateWorkExperience';
import UpdateSkills from './components/profileChildComponents/UpdateSkills';
import { auth } from './utils/firebaseInit';
import { getUserData } from './api/FetchDataService';
import { signInAction, onboardUserAction, resetAction } from './actions/Actions';
import "./cool.scss";
import { onAuthStateChanged } from 'firebase/auth';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingButton } from '@mui/lab';
import boostImg from "./static/boost-img.png";
import Cold from './components/Cold';
import Cover from './components/Cover';
import TryItOut from './components/TryItOut';

function App() {

    const [store, dispatch] = useReducer(reducer, initialState);

    const [loading, setLoading] = useState(true);
    console.log("store");
    console.log(store);

    useEffect(()=>{
        onAuthStateChanged(auth, async (user) => {

            console.log("AUTH STATE CHANGE");
            setLoading(true);
            if (user) {
                const uid = user.uid;
                const userData = await getUserData(uid);
                
                if (!userData.error) {
                    setLoading(false); 
                    dispatch(signInAction(userData, { user: user }));
                } else {
                    setLoading(false);
                    dispatch(onboardUserAction({ user: user }));
                }
              // User is signed in, see docs for a list of available properties
              // https://firebase.google.com/docs/reference/js/firebase.User
              // ...
              console.log("uid", uid);
              console.log(user);
              setLoading(false);
            } else {
              // User is signed out
              // ...
              console.log("user is logged out");
              dispatch(resetAction());
              setLoading(false);
            }
          });
         
    }, []);

    if(loading) {
        return (
            <div className="App">
            <div className="App-header">
                <div className="cta-container">
                    <img src={boostImg} />
                    <br />
                    <h1 className="logo big">boosted.fyi</h1>

                    <LoadingButton
                        loading
                        loadingPosition="start"
                        startIcon={<FontAwesomeIcon icon={faCircle} />}
                        variant="outlined"
                        >
                        Loading
                    </LoadingButton>
                </div>
            </div>
        </div>
        )
    }

    if (store.loggedIn) {

        if (store.onboarded) {
            return (
                <Context.Provider value={{store, dispatch}}>
                    <Router>
                        <Routes>
                            <Route path="/" element={<Home />} />
                            <Route path="/morph" element={<Morph />} />
                            <Route path="/cover" element={<Cover />} />
                            <Route path="/cold" element={<Cold />} />
                            <Route path="/profile" element={<Profile />} />
                            <Route path="/updateWorkExperience" element={<UpdateWorkExperience />} />
                            <Route path="/updateEducation" element={<UpdateEducation />} />
                            <Route path="/updateSkills" element={<UpdateSkills />} />
                        </Routes>
                    </Router>
                </Context.Provider>
            )
        }

        return (
            <Context.Provider value={{store, dispatch}}>
                <Router>
                    <Routes>
                        <Route path="/" element={<OnboardUser />} />
                        <Route
                            path="*"
                            element={
                                <Navigate to={"/"} />
                            }
                        />
                    </Routes>
                </Router>
            </Context.Provider>
        )
        
    }

    return (
        <Context.Provider value={{store, dispatch}}>
            <Router>
                <Routes>
                    <Route path="/" element={<SignIn />} />
                    <Route path="/trial" element={<TryItOut />} />
                    <Route
                        path="*"
                        element={
                            <Navigate to={"/"} />
                        }
                    />
                </Routes>
            </Router>
        </Context.Provider>
    );
}

export default App;
