import { Grid, TextField, Button } from "@mui/material";
import { useContext, useState } from "react";
import { setOnboardingStepAction, addSkillOnboardingAction, updateSkillsOnboardingAction } from "../../actions/Actions";
import { OnboardingStep } from "../../constants/Constants";
import { Context } from "../../GlobalStore";

const Skills = () => {
    const {store, dispatch} = useContext(Context);

    const [skill, setSkill] = useState("");

    const addSkill = (event: any) => {
        if(event.code === 'Enter') {
            const newSkill = skill.trim();
            // const skillSet = new Set([
            //     ...store.onboarding.skills,
            //     newSkill
            // ]);
            dispatch(addSkillOnboardingAction(newSkill));
            setSkill('');
        }
    };

    return (
        <>
            <div className="w90">
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} className="left-text">
                        <h6>Your Skills</h6>
                        {
                            store.onboarding.skills.length > 0 ?
                            <div className="skills-tag-container">
                                {
                                    store.onboarding.skills.map((sk: string) => {
                                        return (
                                            <span className="skills-tag">{sk} <span className="red" onClick={() => {
                                                dispatch(updateSkillsOnboardingAction(store.onboarding.skills.filter((sk1) => sk1 !== sk)));
                                            }}>x</span></span>   
                                        )
                                    })
                                }
                            </div> :
                            <span className="step-message">You have no skills added. Please add at leaset 1 skill to continue</span>
                        }
                        <br />
                        <br />
                        <TextField className="jd-form" id="outlined-basic" label="Enter a skill and hit enter to add it to your list of skills" 
                        variant="standard" value={skill} onChange={(e) => setSkill(e.currentTarget.value)} onKeyUp={addSkill} />
                        <br />
                        <br />
                        <div className="button-container">
                            <Button size={'small'} variant="outlined" className="morph-step-button-outlined" onClick={() => {
                                dispatch(addSkillOnboardingAction(skill));
                            }}>
                                + Add
                            </Button>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6} >
                    </Grid>
                </Grid>
                <br />
                <div className="button-container">
                    <Button size={'small'} variant="contained" className="morph-step-button" onClick={() => dispatch(setOnboardingStepAction(OnboardingStep.WORK_EXPERIENCE))}>
                        &larr; Back
                    </Button>
                    <Button size={'small'} variant="contained" className="morph-step-button" onClick={() => dispatch(setOnboardingStepAction(OnboardingStep.REVIEW))}>
                        Next &rarr;
                    </Button>
                </div>
            </div>
        </>
    )
}

export default Skills;