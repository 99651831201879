import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LoadingButton } from "@mui/lab";
import { AppBar, Button, Grid, TextField, Toolbar, Typography } from "@mui/material";
import { title } from "process";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { updateUserAction } from "../../actions/Actions";
import { addWorkExperience, updateWorkExperience } from "../../api/FetchDataService";
import { MorphStep } from "../../constants/Constants";
import { Context } from "../../GlobalStore";
import LoggedInTopBar from "../LoggedInTopBar";
import WorkExCard from "./WorkExCard";
import _ from "lodash";

const initialState = {
    designation: "",
    company: "",
    description: "",
    startDate: "",
    endDate: "",
    currentlyEmployed: false
}

const UpdateWorkExperience = () => {

    const navigate = useNavigate();

    const {store, dispatch} = useContext(Context);

    const [workExObj, setworkExObj] = useState(initialState);

    const [newWork, setNewWork] = useState([...store.user.workExperience] as any);

    const [view, setView] = useState(0);

    const [loading, setLoading] = useState(false);

    const saveNewWorkExperiences = async () => {
        setLoading(true);
        const res = await updateWorkExperience(store.user.userId, newWork);
        if (res.error) {
            alert("Error");
            setLoading(false);
        } else {
            dispatch(updateUserAction({...store.user, workExperience: [...newWork] }));
            navigate('/profile');
        }
    }

    const removeWorkEx = (keyObj: any) => {
        const we1 = newWork.filter((item: any) => !(_.isEqual(keyObj, item)));
        setNewWork(we1);
    }
    
    return (
        <div className="App">
            <LoggedInTopBar />
            <br />
            <div className="w90 left-text">
                <h4>Update Work Experiences</h4>
            </div>
            <br />
            <div className="w90">
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} >
                        <br />
                        <TextField className="jd-form" id="outlined-basic" label="Company" variant="standard" value={workExObj.company}
                            onChange={(e) => setworkExObj({...workExObj, company: e.currentTarget.value})} />
                        <br />
                        <TextField className="jd-form" id="outlined-basic" label="Designation" variant="standard" value={workExObj.designation} 
                            onChange={(e) => setworkExObj({...workExObj, designation: e.currentTarget.value})}/>
                        <br />
                        <TextField className="jd-form" id="outlined-basic" label="Description" variant="standard" value={workExObj.description} 
                            onChange={(e) => setworkExObj({...workExObj, description: e.currentTarget.value})} minRows={4} multiline/>
                        <br />
                        <TextField className="jd-form" id="outlined-basic" type={"date"} label="Start Date" variant="standard" value={workExObj.startDate} 
                            onChange={(e) => setworkExObj({...workExObj, startDate: e.currentTarget.value})} focused/>
                        <br />
                        {
                            !workExObj.currentlyEmployed && 
                            <>
                            <TextField className="jd-form" id="outlined-basic" type={"date"} label="End Date" variant="standard" value={workExObj.endDate} 
                                onChange={(e) => setworkExObj({...workExObj, endDate: e.currentTarget.value})} focused/>
                            <br />
                            </>
                        }
                        
                        <div className="form-check checkbox-div">
                            <input className="form-check-input" type="checkbox" value="" id="activeEmployment" 
                                onChange={(e) => setworkExObj({...workExObj, currentlyEmployed: e.currentTarget.checked, endDate: ''})}  />
                            <label className="form-check-label" htmlFor="activeEmployment">
                                Currently Employed
                            </label>
                        </div>
                        <br />
                        <div className="button-container">
                            <Button size={'small'} variant="outlined" className="morph-step-button-outlined" onClick={() => {
                                setNewWork([...newWork, workExObj]);
                                setworkExObj(initialState);
                                setView(0);
                            }}>
                                + Add Work Experience
                            </Button>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6} >
                        <div className="left-text">Preview</div>
                        {
                        newWork.length === 0 ?
                        <div className="no-work">
                            <span>No New Experiences</span>
                        </div> :
                        <div className="full-width left-text">
                            {
                                newWork.map((key: any) => {
                                    return <WorkExCard key={key} workExObject={key} edit={1} editFunction={removeWorkEx} />
                                })
                            }
                            {
                                loading ?
                                <LoadingButton
                                loading
                                startIcon={<FontAwesomeIcon icon={faCircle} />}
                                loadingPosition="start"
                                variant="outlined"
                                >
                                    Loading
                                </LoadingButton> :
                                <Button size={'small'} variant="contained" className="morph-step-button" onClick={saveNewWorkExperiences}>
                                    Save Work Experiences &rarr;
                                </Button>

                            }
                        </div>
                        }    
                    </Grid> 
                </Grid>
            </div>

            <div className="w90 left-text">
                
            </div>
        </div>
    )
}

export default UpdateWorkExperience;