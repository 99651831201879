import { AppBar, Button, Toolbar, Typography } from "@mui/material";
import { useContext } from "react";
import { OnboardingStep, PDFOnboardingStep, TryItOutMode } from "../constants/Constants";
import { Context } from "../GlobalStore";
import Education from "./onboardingChildComponents/Education";
import Review from "./onboardingChildComponents/Review";
import Skills from "./onboardingChildComponents/Skills";
import WorkEx from "./onboardingChildComponents/WorkEx";
import { signOut } from "firebase/auth";
import { auth } from "../utils/firebaseInit";
import { setOnboardingMode, setOnboardingStepAction } from "../actions/Actions";
import StepIndicator from "./common/StepIndicator";
import OnboardingModeSelector from "./onboardingChildComponents/OnboardingModeSelector";

const OnboardUser = () => {

    const {store, dispatch} = useContext(Context);

    const setMode = (mode: TryItOutMode) => dispatch(setOnboardingMode(mode));
    
    const OnboardingStepComponent = () => {
        switch(store.onboarding.step) {
            case OnboardingStep.GET_STARTED:
                return (
                    <div className="w90 left-text">
                        <div className="w50">
                            <h4 className="minipixel7">Welcome to boosted.fyi</h4>
                            <div>We provide 3 different AI based tools to help you land your next job interview</div>
                            <div><b>Boost Resume</b> - We will reword the work experience that you put on your resume based on the details of a given job description. 
                                This will help get past automated resume screening tools and/or project a good fit for the job to any recruiter or hiring manager reading your resume.</div>
                            <div><b>Generate Cover Letter</b> - We will create a cover letter for you based on your experience skills and the job you want to apply for.</div>
                            <div><b>Generate Cold Message</b> - We will generate a brief cold message you can use when making outbound requests to recruiters and organisations you're interested in working with.</div>
                            <br />
                            <h4 className="minipixel7">Getting Started</h4>
                            <div>Get started by creating your profile. Fill in the details of your resume (education, work experience and skills).</div>
                        </div>
                        <br />
                        <div className="button-container">
                            <Button size={'small'} variant="contained" className="morph-step-button" onClick={() => dispatch(setOnboardingStepAction(OnboardingStep.EDUCATION))}>
                                Get Started &rarr;
                            </Button> 
                            <br />
                            <div></div>
                        </div>
                    </div>
                )
            case PDFOnboardingStep.UPLOAD_PDF:
                return <div>whadup</div>
            case OnboardingStep.EDUCATION:
                return <Education />
            case OnboardingStep.WORK_EXPERIENCE:
                return <WorkEx />
            case OnboardingStep.SKILLS:
                return <Skills />
            case OnboardingStep.REVIEW:
                return <Review />
            default:
                return <div></div>
        }
    }

    const steps = store.onboarding.mode === TryItOutMode.MANUAL ? [
        "Welcome to boosted.fyi",
        "Enter the details of your education",
        "Enter the details of your work experience",
        "Enter your skills",
        "Review"
    ] : [
        "Welcome to boosted.fyi",
        "Upload your resume",
        "Review"
    ];

    return (
        <div className="App">
            <AppBar position="static" className="appbar">
                <Toolbar variant="dense">
                    <Typography variant="h6" color="inherit" component="div">
                        <div className="logo">boosted.fyi</div>
                    </Typography>
                </Toolbar>
                <div className="nav-bar-button-container">
                    <Button className="nav-bar-button" size="small" onClick={() => {
                        signOut(auth);
                    }}>Sign Out</Button>
                </div>
            </AppBar>
            <br />
            <div className="w90 left-text">
                    <h1>Welcome to boosted.fyi</h1>
            </div>
            <br />
            <div className="w90">
                <div className="profile-top-bar">
                    <div className="profile-view-bar">
                        <OnboardingModeSelector 
                            selected={store.onboarding.mode === TryItOutMode.PDF} 
                            onClick={setMode} 
                            value={TryItOutMode.PDF} label={"Upload Resume"} />
                        <OnboardingModeSelector 
                            selected={store.onboarding.mode === TryItOutMode.MANUAL} 
                            onClick={setMode} value={TryItOutMode.MANUAL} 
                            label={"Enter Work Details"} />
                    </div>
                </div>
            </div>
            <StepIndicator
                activeStep={store.onboarding.step}
                steps={steps}
            />
            <br />
            <OnboardingStepComponent />
        </div>
    );
}

export default OnboardUser;