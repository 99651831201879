export const TryItOutIntro = () => {
    return (
        <div className="w50">
            <h4 className="minipixel7">Overview</h4>
            <div>We provide 3 different AI based tools to help you land your next job interview</div>
            <div><b>Boost Resume</b> - We will reword the work experience that you put on your resume based on the details of a given job description. 
                This will help get past automated resume screening tools and/or project a good fit for the job to any recruiter or hiring manager reading your resume.</div>
            <div><b>Generate Cover Letter</b> - We will create a cover letter for you based on your experience skills and the job you want to apply for.</div>
            <div><b>Generate Cold Message</b> - We will generate a brief cold message you can use when making outbound requests to recruiters and organisations you're interested in working with.</div>
            <br />
            <h4 className="minipixel7">Getting Started</h4>
            <div>1. Enter the details of your work experience and skills</div>
            <div>2. Enter the details of the job opening you're applying to</div>
            <div>3. Choose the tool you want to use.</div>
        </div>
    )
}