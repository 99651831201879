
import { faGoogle } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LoadingButton } from "@mui/lab";
import { Button } from "@mui/material";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { useContext, useState } from "react";
import { onboardUserAction, signInAction } from "../actions/Actions";
import { getUserData } from "../api/FetchDataService";
import { Context } from "../GlobalStore";
import { auth } from "../utils/firebaseInit";
import boostImg from "../static/boost-img.png";
import { Navigate, useNavigate } from "react-router-dom";

const SignIn = () => {

    const googleProvider = new GoogleAuthProvider();

    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);

    const {store, dispatch} = useContext(Context);

    const googleLogin = async () => {
        try {
            setLoading(true);
            await signInWithPopup(auth, googleProvider);
            // const result  = await signInWithPopup(auth, googleProvider);
            // const uid = result.user.uid;
            // const userData = await getUserData(uid);
            
            // if (!userData.error) {
            //     setLoading(false); 
            //     dispatch(signInAction(userData, result));
            // } else {
            //     setLoading(false);
            //     dispatch(onboardUserAction(result));
            // }
        } catch (e) {
            setLoading(false);
            console.log(e);
        }
    }

    return (
        <div className="App">
            <div className="App-header">
                <div className="cta-container">
                    <img src={boostImg} />
                    <br />
                    <h1 className="logo big">boosted.fyi</h1>

                    <h5>Boost your chances of landing your next job interview with our AI powered tools</h5>
                    <br />

                    {
                        loading ?
                        <LoadingButton
                        loading
                        loadingPosition="start"
                        startIcon={<FontAwesomeIcon icon={faGoogle} />}
                        variant="outlined"
                        className="sign-in-loading"
                        >
                            Signing In
                        </LoadingButton> :
                        <div className="sign-in-page-button-container">
                            <Button onClick={googleLogin} variant="contained" className="sign-in-button" startIcon={<FontAwesomeIcon icon={faGoogle} />}>
                                Sign In
                            </Button>

                            <Button onClick={() => navigate('/trial')} variant="outlined" className="try-it-out-button">
                                Try it out !
                            </Button>
                        </div>
                        // <button onClick={googleLogin} className="btn btn-dark">Sign In With Google</button>
                    }
                </div>
            </div>
        </div>
    )
}

export default SignIn;