export enum OnboardingStep {
    GET_STARTED,
    EDUCATION,
    WORK_EXPERIENCE,
    SKILLS,
    REVIEW
}

export enum PDFOnboardingStep {
    GET_STARTED,
    UPLOAD_PDF,
    REVIEW
}

export enum MorphStep {
    SELECT_WORK_EX,
    ENTER_JD,
    RESULT
}

export enum TryItOutStep {
    INTRO,
    // ENTER_WORK_EX,
    PDF,
    CHOOSE_OPTION,
    ENTER_JD,
    RESULT
}

export enum TrialOption {
    BOOST,
    COVER,
    COLD
}

export enum ProfileView {
    WORK_EX,
    EDUCATION,
    SKILLS
}

export enum TryItOutMode {
    PDF,
    MANUAL
}

export interface WorkExperience {
    description: string;
    designation: string;
    company: string;
    startDate: string;
    endDate: string | null;
    currentlyEmployed: boolean;
}

export interface Education {
    level: string;
    startDate: string;
    endDate: string;
    discipline: string;
    schoolName: string;
    currentlyEnrolled: string;
}

export const EducationLevels: { [key: string]: string } = {
    MASTERS: "Masters",
    BACHELORS: "Bachelors",
    DIPLOMA: "Diploma",
    CERTIFICATION: "Certification",
    HIGH_SCHOOL: "High School"
}

export const TryItOutResultStepLabels = [
    "How it works",
    "Enter the details of your work experience",
    "Choose the tool you would like to use",
    "Enter the details of the job opening you're applying to",
    "Processing"
];

export const TryItOutStepLabels = [
    "How it works",
    "Enter the details of your work experience and skills",
    "Choose the tool you would like to use",
    "Enter the details of the job opening you're applying to",
    "Here's your reworded work experience"
];