import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LoadingButton } from "@mui/lab";
import { Grid, TextField, Button } from "@mui/material";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { updateUserAction } from "../../actions/Actions";
import { updateSkills } from "../../api/FetchDataService";
import { Context } from "../../GlobalStore";
import LoggedInTopBar from "../LoggedInTopBar";

const UpdateSkills = () => {

    const navigate = useNavigate();

    const {store, dispatch} = useContext(Context);

    const [skill, setSkill] = useState('');
    const [skills, setSkills] = useState<string[]>(store.user.skills);

    const addSkill = (event: any) => {
        if(event.code === 'Enter') {
            const newSkill = skill.trim();
            const skillSet = new Set([
                ...skills,
                newSkill
            ]);
            setSkills([...Array.from(skillSet)]);
            setSkill('');
        }
    };

    const [loading, setLoading] = useState(false);

    const saveSkills = async () => {
        setLoading(true);
        const res = await updateSkills(store.user.userId, skills);
        if (res.error) {
            alert("Error");
            setLoading(false);
        } else {
            dispatch(updateUserAction({...store.user, skills: [...skills] }));
            navigate('/profile');
        }
    }
    
    return (
        <div className="App">
            <LoggedInTopBar />
            <br />
            <div className="w90 left-text">
                <h4>Update Skills</h4>
            </div>
            <br />
            <div className="w90">
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} className="left-text">
                    <h6>Your Skills</h6>
                    {
                        skills.length > 0 ?
                        <div className="skills-tag-container">
                            {
                                skills.map((sk: string) => {
                                    return (
                                        <span key={sk} className="skills-tag">{sk} <span className="red" onClick={() => {
                                            setSkills(skills.filter((sk1) => sk1 !== sk));
                                        }}>x</span></span>   
                                    )
                                })
                            }
                        </div> :
                        <span className="step-message">You have no skills added. Please add at leaset 1 skill to continue</span>
                    }
                    <br />
                    <br />
                    <TextField className="jd-form" id="outlined-basic" label="Enter a skill and hit enter to add it to your list of skills" 
                        variant="standard" value={skill} onChange={(e) => setSkill(e.currentTarget.value)} onKeyUp={addSkill} />
                    <br />
                    <div className="button-container">
                        {
                                skills.length > 0 &&
                                (loading ?
                                <LoadingButton
                                loading
                                startIcon={<FontAwesomeIcon icon={faCircle} />}
                                loadingPosition="start"
                                variant="outlined"
                                >
                                    Loading
                                </LoadingButton> :
                                <Button size={'small'} variant="contained" className="morph-step-button" onClick={saveSkills}>
                                    Save Skills &rarr;
                                </Button>)

                        }
                    </div>
                    </Grid>
                    <Grid item xs={12} sm={6} >
                        
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}

export default UpdateSkills;