import { useState } from "react";
import { WorkExperience } from "../../constants/Constants";

const WorkExCard = (props: {workExObject: WorkExperience, edit?: number | undefined, editFunction?: Function | undefined}) => {

    const {
        designation,
        description,
        company,
        startDate,
        endDate,
        currentlyEmployed
    } = props.workExObject;

    const [view, setView] = useState(false);

    const toggleView = () => {
        setView(!view);
    }

    const remove = (e: any) => {
        e.preventDefault();
        props.editFunction?.(props.workExObject);
    }

    const className = view ? "work-ex-card work-ex-card-active" : "work-ex-card";

    return (
        <div className={className} onClick={toggleView}>
            <div className="work-ex-card-company">{company}</div>
            <div className="work-ex-card-designation">{designation}</div>
            <div className="work-ex-card-date">
                {startDate} to {
                    currentlyEmployed ?
                    "Present" :
                    endDate
                }
            </div>
            { view && <div className="work-ex-card-description">{description}</div> }
            <br />
            {
                props.edit && <span onClick={remove} className="remove-button">Remove</span>
            }
        </div>
    )
}

export default WorkExCard;