// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { browserLocalPersistence, getAuth, setPersistence } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBY8qYxXNg1jfPE7FAb5Lhtczj7thXaRQQ",
  authDomain: "resumorph.firebaseapp.com",
  projectId: "resumorph",
  storageBucket: "resumorph.appspot.com",
  messagingSenderId: "6705980666",
  appId: "1:6705980666:web:1cd44442c65f4293355543",
  measurementId: "G-5QZR51C6TR"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export const auth = getAuth();
setPersistence(auth, browserLocalPersistence);