import { Grid, Button, TextField, LinearProgress } from "@mui/material";
import { useContext, useState } from "react"
import { Link } from "react-router-dom";
import { getCoverLetterResponse } from "../api/FetchDataService";
import { MorphStep } from "../constants/Constants";
import { Context } from "../GlobalStore"
import SectionHeading from "./common/SectionHeading";
import StepIndicator from "./common/StepIndicator";
import Cool from "./Cool";
import LoggedInTopBar from "./LoggedInTopBar"
import WorkSelectInput from "./morphChildComponents/WorkSelectInput";
import { ContactPageSharp } from "@mui/icons-material";

const Component = () => {

    const {store, dispatch} = useContext(Context);

    let workEx: any = [];

    store.user.workExperience.forEach((value: any, index: any, array: any) => {
        workEx.push({
            index: index,
            ...value
        })
    });

    const [view, setView] = useState(MorphStep.SELECT_WORK_EX);

    const skills = store.user.skills[0];
    const edu = store.user.education[0];

    const [loading, setLoading] = useState(false);
    const [title, setTitle] = useState('');
    const [jd, setJd] = useState('');
    const [qualifications, setQualifications] = useState('');

    const [result, setResult] = useState('');

    const [selectedWorkEx, setSelectedWorkEx] = useState(0);

    const getCoverLetter = async () => {
        // alert(`${title}\n${jd}\n${qualifications}`);
        setLoading(true);
        const res = await getCoverLetterResponse(
            title,
            jd,
            qualifications,
            workEx[selectedWorkEx].designation,
            workEx[selectedWorkEx].description,
            skills,
            edu.level + " " + edu.discipline
        );
        if (res.error) {
            alert("ERROR");
        } else {
            setLoading(false);
            setView(MorphStep.RESULT);
            setResult(res.result.content);
        }
    }

    const handleRadioChange = (e: any) => {
        setSelectedWorkEx(parseInt(e.currentTarget.value));
    }

    const copyResult = () => {
        if (navigator.clipboard) {
            navigator.clipboard.writeText(result).then(() => {
                console.log("copied");
            }).catch((e) => {
                console.log("error: ", e);
            })
        }
    }

    if(store.user.workExperience.length === 0) {
        return (
            <div className="App">
                <LoggedInTopBar />
                < br/>
                <div className="w90 left-text">
                <ContactPageSharp />
                </div>
                <SectionHeading
                    title={"Generate Cover Letter"}
                    subtitle={`Catch the attention of the recruiter hiring for the job you're applying for. 
                    Write a brief cover letter that highlights your skills, experience and fit for the job.`}
                />
                <br />
                <div className="no-work w90">
                    <p>You have not added any work experiences. Click <Link to={"/addWorkExperience"}>here</Link> to add work experience.</p>
                </div>
            </div>
        )
    }

    if(loading) {
        return (
            <div className="App">
                <LoggedInTopBar />
                <br />
                <div className="w90 left-text">
                <ContactPageSharp />
                </div>
                <SectionHeading
                    title={"Generate Cover Letter"}
                    subtitle={`Catch the attention of the recruiter hiring for the job you're applying for. 
                    Write a brief cover letter that highlights your skills, experience and fit for the job.`}
                />
                <br />
                <StepIndicator
                    activeStep={MorphStep.RESULT}
                    steps={[
                        "Select the work experience to reword",
                        "Enter the details of the job opening you're applying to",
                        "Loading"
                    ]}
                />
                <br />
                <div className="w90 loading-message">
                    <LinearProgress color="inherit" />
                    <br />
                    <h3>Processing ...</h3>
                    <div>This could take some time. Please be patient. Do not refresh the page.</div>
                </div>
            </div>
        )
    }



    return (
        <div className="App">
            <LoggedInTopBar />
            < br/>
            <div className="w90 left-text">
            <ContactPageSharp />
            </div>
            <SectionHeading
                title={"Generate Cover Letter"}
                subtitle={`Catch the attention of the recruiter hiring for the job you're applying for. 
                Write a brief cover letter that highlights your skills, experience and fit for the job.`}
            />
            <br />
            <StepIndicator
                activeStep={view}
                steps={[
                    "Select the work experience to highlight in your cover letter",
                    "Enter the details of the job opening you're applying to",
                    "Here customized cover letter"
                ]}
            />
            <br />
            {
                view === MorphStep.SELECT_WORK_EX ? 
                <>
                    <div className="work-select-container w90">
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} >
                            {
                                workEx.map((key: any) => {
                                    return (
                                        <WorkSelectInput 
                                            key={key.index}
                                            selected={selectedWorkEx === key.index}
                                            workExObject={key}
                                            onClick={handleRadioChange} />
                                        
                                    )
                                })
                            }
                            <div className="button-container">
                                <Button size={'small'} variant="contained" className="morph-step-button" onClick={() => setView(MorphStep.ENTER_JD)}>
                                    Next
                                </Button> 
                                <br />
                                <div></div>
                            </div>
                                
                            </Grid>
                            <Grid item xs={12} sm={6}>
                            <div className="w90 original-work-preview">
                                <h6>Preview Original Work Experience</h6>
                                <p>{workEx[selectedWorkEx].description}</p>
                            </div>
                            </Grid>
                        </Grid>
                    </div>
                </> :
                view === MorphStep.ENTER_JD ?
                <div className="w90">
                    <TextField className="jd-form" id="outlined-basic" label="Job Title" variant="standard" value={title} onChange={(e) => setTitle(e.currentTarget.value)} />
                    <br />
                    <TextField className="jd-form" id="outlined-basic" label="Job Description" variant="standard" rows={4} value={jd} onChange={(e) => setJd(e.currentTarget.value)} multiline/>
                    <br />
                    <TextField className="jd-form" id="outlined-basic" label="Required Qualifications" variant="standard" rows={4} value={qualifications} onChange={(e) => setQualifications(e.currentTarget.value)} multiline/>
                    <br />
                    <div className="button-container">
                        <Button size={'small'} variant="contained" className="morph-step-button" onClick={() => setView(MorphStep.SELECT_WORK_EX)}>
                            Back
                        </Button>

                        <Button size={'small'} variant="contained" className="morph-step-button" onClick={getCoverLetter}>
                            Submit
                        </Button>
                    </div>
                </div> :
                <div className="w90">
                    <div className="w90 result-preview">
                        <h4>Result</h4>
                        <div>
                            <p>{ result }</p>
                        </div>
                        <br />
                        <Button size={'small'} variant="outlined" className="copy" onClick={copyResult}>Copy Result</Button>
                        <Button size={'small'} variant="contained" className="morph-step-button" onClick={getCoverLetter}>
                            Regenerate
                        </Button>
                    </div>
                </div>

            }
        </div>
    )
}

export default Component;