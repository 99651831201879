import { Button, Grid, LinearProgress, TextField } from "@mui/material";
import { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { getResumorphResponse } from "../api/FetchDataService";
import { MorphStep } from "../constants/Constants";
import { Context } from "../GlobalStore";
import SectionHeading from "./common/SectionHeading";
import StepIndicator from "./common/StepIndicator";
import LoggedInTopBar from "./LoggedInTopBar";
import WorkSelectInput from "./morphChildComponents/WorkSelectInput";
import { BoltSharp } from "@mui/icons-material";
import { faCopy } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Morph = () => {

    const navigate = useNavigate();

    const {store, dispatch} = useContext(Context);

    let workEx: any = [];

    store.user.workExperience.forEach((value: any, index: any, array: any) => {
        workEx.push({
            index: index,
            ...value
        })
    });

    const [view, setView] = useState(MorphStep.SELECT_WORK_EX);

    const skills = store.user.skills[0];
    const edu = store.user.education[0];

    const [loading, setLoading] = useState(false);
    const [title, setTitle] = useState('');
    const [jd, setJd] = useState('');
    const [qualifications, setQualifications] = useState('');

    const [result, setResult] = useState([]);

    const [selectedWorkEx, setSelectedWorkEx] = useState(0);

    const getMorphedWorkex = async () => {
        // alert(`${title}\n${jd}\n${qualifications}`);
        setLoading(true);
        const res = await getResumorphResponse(
            title,
            jd,
            qualifications,
            workEx[selectedWorkEx].designation,
            workEx[selectedWorkEx].description,
            skills,
            edu.level + " " + edu.discipline
        );
        if (res.error) {
            alert("ERROR");
        } else {
            setLoading(false);
            setView(MorphStep.RESULT);
            setResult(JSON.parse(res.result.content).result);
        }
    }

    const handleRadioChange = (e: any) => {
        setSelectedWorkEx(parseInt(e.currentTarget.value));
    }

    const copyResult = () => {
        if (navigator.clipboard) {
            navigator.clipboard.writeText(result.join("\n")).then(() => {
                console.log("copied");
            }).catch((e) => {
                console.log("error: ", e);
            })
        }
    }

    if(store.user.workExperience.length === 0) {
        return (
            <div className="App">
                <LoggedInTopBar />
                < br/>
                <div className="w90 left-text">
                    <BoltSharp />
                </div>
                <SectionHeading
                    title={"Boost Resume"}
                    subtitle={`Reword the description of your resume to match the requirements of the job opening you 
                    are applying to and boost your chances of getting called back for an interview`}
                />
                <br />
                <div className="no-work w90">
                    <p>You have not added any work experiences. Click <Link to={"/addWorkExperience"}>here</Link> to add work experience.</p>
                </div>
            </div>
        )
    }

    if(loading) {
        return (
            <div className="App">
                <LoggedInTopBar />
                <br />
                <div className="w90 left-text">
                    <BoltSharp />
                </div>
                <SectionHeading
                    title={"Boost Resume"}
                    subtitle={`Reword the description of your resume to match the requirements of the job opening you 
                    are applying to and boost your chances of getting called back for an interview`}
                />
                <br />
                <StepIndicator
                    activeStep={MorphStep.RESULT}
                    steps={[
                        "Select the work experience to reword",
                        "Enter the details of the job opening you're applying to",
                        "Loading"
                    ]}
                />
                <br />
                <div className="w90 loading-message">
                    <LinearProgress color="inherit" />
                    <br />
                    <h3>Processing ...</h3>
                    <div>This could take some time. Please be patient. Do not refresh the page.</div>
                </div>
            </div>
        )
    }
    
    return (
        <div className="App">
            <LoggedInTopBar />
            <br />
            <div className="w90 left-text">
                <BoltSharp />
            </div>
            <SectionHeading
                title={"Boost Resume"}
                subtitle={`Reword the description of your resume to match the requirements of the job opening you 
                are applying to and boost your chances of getting called back for an interview`}
            />
            <br />
            <StepIndicator
                activeStep={view}
                steps={[
                    "Select the work experience to reword",
                    "Enter the details of the job opening you're applying to",
                    "Here's your reworded work experience"
                ]}
            />
            <br />
            {
                view === MorphStep.SELECT_WORK_EX ? 
                <>
                    <div className="work-select-container w90">
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} >
                            {
                                workEx.map((key: any) => {
                                    return (
                                        <WorkSelectInput 
                                            key={key.index}
                                            selected={selectedWorkEx === key.index}
                                            workExObject={key}
                                            onClick={handleRadioChange} />
                                        
                                    )
                                })
                            }
                            <div className="button-container">
                                <Button size={'small'} variant="contained" className="morph-step-button" onClick={() => setView(MorphStep.ENTER_JD)}>
                                    Next
                                </Button> 
                                <br />
                                <div></div>
                            </div>
                                
                            </Grid>
                            <Grid item xs={12} sm={6}>
                            <div className="w90 original-work-preview">
                                <h6>Preview Original Work Experience</h6>
                                <p>{workEx[selectedWorkEx].description}</p>
                            </div>
                            </Grid>
                        </Grid>
                    </div>
                </> :
                view === MorphStep.ENTER_JD ?
                <div className="w90">
                    <TextField className="jd-form" id="outlined-basic" label="Job Title" variant="standard" value={title} onChange={(e) => setTitle(e.currentTarget.value)} />
                    <br />
                    <TextField className="jd-form textarea" id="outlined-basic" label="Job Description" variant="standard" rows={4} value={jd} onChange={(e) => setJd(e.currentTarget.value)} multiline/>
                    <br />
                    <TextField className="jd-form" id="outlined-basic" label="Required Qualifications" variant="standard" rows={4} value={qualifications} onChange={(e) => setQualifications(e.currentTarget.value)} multiline/>
                    <br />
                    <div className="button-container">
                        <Button size={'small'} variant="contained" className="morph-step-button" onClick={() => setView(MorphStep.SELECT_WORK_EX)}>
                            Back
                        </Button>

                        <Button size={'small'} variant="contained" className="morph-step-button" onClick={getMorphedWorkex}>
                            Submit
                        </Button>
                    </div>
                </div> :
                <div className="w90">
                    <div className="result-preview">
                        <h4>Result</h4>
                        <div>
                            <ul>
                                {
                                    result.map((point) => {
                                        return <li>{point}</li>
                                    })
                                }
                            </ul>
                        </div>
                        <br />
                        <Button size={'small'} variant="outlined" className="copy" onClick={copyResult}>Copy Result</Button>
                        <Button size={'small'} variant="contained" className="morph-step-button" onClick={getMorphedWorkex}>
                            Regenerate
                        </Button>
                    </div>
                </div>

            }
            
        </div>
    );
}

export default Morph;