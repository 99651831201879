import { AppBar, Toolbar, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

export const TryItOutAppBar = () => {
    const navigate = useNavigate();
    return (
        <AppBar position="static" className="appbar">
            <Toolbar variant="dense">
                <Typography variant="h6" color="inherit" component="div">
                    <div className="logo" onClick={() => navigate('/')}>boosted.fyi</div>
                </Typography>
            </Toolbar>
        </AppBar>
    )
}